import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CssBaseline,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getHeader, toastError } from "../../utils/util";
import axios from "axios";
import { API_ENDPOINT } from "../../constants/constants";
import { toast } from "react-toastify";

const PasswordReset = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetting, setResetting] = useState(false);
  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const validateCurrentPassword = () => {
    let isValid = true;
    if (currentPassword.length < 1) {
      setErrors((prev) => ({
        ...prev,
        currentPassword: "Current password is required",
      }));
      isValid = false;
    }
    return isValid;
  };

  const validateNewPassword = () => {
    let isValid = true;
    if (newPassword.length < 8) {
      setErrors((prev) => ({
        ...prev,
        newPassword: "Password must be at least 8 characters",
      }));
      isValid = false;
    }
    return isValid;
  };

  const validateConfirmPassword = () => {
    let isValid = true;
    if (newPassword !== confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isCurrentValid = validateCurrentPassword();
    const isNewValid = validateNewPassword();
    const isConfirmValid = validateConfirmPassword();

    if (isCurrentValid && isNewValid && isConfirmValid) {
      try {
        const { data } = await axios.post(
          `${API_ENDPOINT}admin/reset-password`,
          { currentPassword, newPassword, confirmPassword },
          getHeader()
        );
        toast.success(data.message);
      } catch (error) {
        toastError(error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, width: "100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="Current Password"
            type="password"
            autoComplete="current-password"
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
              setErrors((prev) => ({ ...prev, currentPassword: "" }));
            }}
            error={!!errors.currentPassword}
            helperText={errors.currentPassword}
            onBlur={validateCurrentPassword}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              setErrors((prev) => ({ ...prev, newPassword: "" }));
            }}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
            onBlur={validateNewPassword}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setErrors((prev) => ({ ...prev, confirmPassword: "" }));
            }}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            onBlur={validateConfirmPassword}
          />

          <LoadingButton
            type="submit"
            fullWidth
            loading={resetting}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordReset;

import Header from "../header/header";
import LeftMenuLogo from "../widgets/leftMenuLogo";
import MenuButton from "../widgets/menuButton";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import TopicOutlinedIcon from "@mui/icons-material/TopicOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import FoundationIcon from "@mui/icons-material/Foundation";
import { Chart } from "react-google-charts";
import "./home.css";
import { useEffect, useState } from "react";
import CardInfo from "../widgets/cardInfo";
import Dashboard from "./dashboard";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import ResetPassword from "../../login/resetPassword";
import MockTest from "../mocktest/mockTest";
import { deleteToken } from "../../utils/util";
import QuestionLibrary from "../questionLibrary/questionLibrary";
import Membership from "../membership/membership";
import EmailScheduler from "../emailScheduler/emailScheduler";
import AuditLog from "../auditLog/auditLog";
import ErrorLog from "../errorLog/errorLog";
import MockTests from "../mocktest/mocktests";
import CreateMockTest from "../create-mocktest/createMockTest";
import { MOBILE_THRESHOLD } from "../../constants/constants";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Products from "../products/products";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import Plans from "../subscription/plans";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import GroupIcon from "@mui/icons-material/Group";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Customers from "../customers/customers";
import Orders from "../orders/orders";
import DeliveryBoys from "../delivery-boys/deliveryBoys";
import Issues from "../issues/issues";
import Banners from "../banners/banners";
import Staffs from "../staffs/staffs";
import Notifications from "../notifications/notifications";
import Categories from "../categories/categories";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import Transactions from "../transactions/transactions";
import TimelineIcon from "@mui/icons-material/Timeline";
import Timeline from "../timelines/timeline";
import UserDetails from "../orders/UserDetails";
import CocoonStock from "../customers/CocoonStock";
import SettingsPage from "../settings/SettingsPage";
import Support from "../support/support";
import PasswordReset from "./PasswordReset";
const Home = (props) => {
  const loc = document.location.href.replace(/\/+$/, "");
  const keysUrl = loc.split("/");
  let pos = "";
  if (keysUrl.length > 3) {
    pos = keysUrl[4]?.split("?")[0]?.split("#")[0];
  }
  console.log(pos);
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    if (windowSize.innerWidth < MOBILE_THRESHOLD) {
      setCollapse(true);
    }
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const logout = () => {
    deleteToken();
    navigate("/login");
  };
  return (
    <div className="home">
      <Header
        logout={logout}
        windowSize={windowSize}
        title={pos}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <div className={collapse ? "left_menu_box collapse" : "left_menu_box"}>
        {windowSize.innerWidth < MOBILE_THRESHOLD && !collapse && (
          <IconButton
            onClick={(e) => setCollapse(true)}
            sx={{ position: "absolute", top: "12px", right: "8px" }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
        <LeftMenuLogo collapse={collapse} />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            boxShadow: "5px 0 5px -5px rgb(176 210 238)",
            overflowY: "auto",
          }}
        >
          <MenuButton
            to="dashboard"
            collapse={collapse}
            text="My Dashboard"
            MenuIcon={DashboardCustomizeOutlinedIcon}
            active={pos === "dashboard"}
          />
          <MenuButton
            to="users"
            collapse={collapse}
            text="Users"
            MenuIcon={GroupIcon}
            active={pos === "users"}
          />
          <MenuButton
            to="orders"
            collapse={collapse}
            text="Orders"
            MenuIcon={AddShoppingCartIcon}
            active={pos === "orders"}
          />
          <MenuButton
            to="transactions"
            collapse={collapse}
            text="Payments"
            MenuIcon={DeliveryDiningIcon}
            active={pos === "transactions"}
          />
          <MenuButton
            to="timelines"
            collapse={collapse}
            text="Timelines"
            MenuIcon={TimelineIcon}
            active={pos === "timelines"}
          />
          <MenuButton
            to="cocoon-stock"
            collapse={collapse}
            text="Cocoon Stock"
            MenuIcon={FoundationIcon}
            active={pos === "cocoon-stock"}
          />
          <MenuButton
            to="notifications"
            collapse={collapse}
            text="Notifications"
            MenuIcon={NotificationsNoneIcon}
            active={pos === "notifications"}
          />
          <MenuButton
            to="user-support"
            collapse={collapse}
            text="User Support"
            MenuIcon={ContactSupportIcon}
            active={pos === "user-support"}
          />
          <MenuButton
            to="settings"
            collapse={collapse}
            text="Settings"
            MenuIcon={SettingsIcon}
            active={pos === "settings"}
          />

          {/* <MenuButton onClick={logout} collapse={collapse} text="Logout" MenuIcon = {LogoutIcon} /> */}
        </div>
      </div>
      <div
        style={{
          marginLeft: collapse
            ? 70
            : windowSize.innerWidth < MOBILE_THRESHOLD
            ? 70
            : 200,
          padding: 16,
        }}
      >
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Customers />} />
          <Route path="users/:userId" element={<UserDetails />} />
          <Route path="orders/*" element={<Orders />} />
          <Route path="transactions/*" element={<Transactions />} />
          <Route path="timelines" element={<Timeline />} />
          <Route path="cocoon-stock" element={<CocoonStock />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="banners" element={<Banners />} />
          <Route path="user-support" element={<Support />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="notifications" element={<Notifications />} />
        </Routes>
      </div>
    </div>
  );
};
export default Home;

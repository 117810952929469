import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./components/home/home";
import Login from "./login/login";
import NotFound from "./NotFound";
import PrivatePath from "./privatePath";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isLoggedIn } from "./utils/util";
import SeedOrderBill from "./components/orders/bills/SeedOrderBill";
import { QueryClient, QueryClientProvider } from "react-query";
import TransactionDetails from "./components/transactions/TransactionDetails";
import SeedOrderBillCrc from "./components/orders/bills/SeedOrderBillCrc";
import AdminLogin from "./login/AdminLogin";
const queryClient = new QueryClient();
const datO = {
  sspPrice: {
    bvPrice: 0,
    bvLimit: 0,
    cbPrice: 0,
    cbLimit: 0,
    pcCocoon: 0,
    pcLimit: 0,
  },
  crcPrice: {
    bvPrice: 0,
    bvLimit: 0,
    cbPrice: 0,
    cbLimit: 0,
    pcCocoon: 0,
    pcLimit: 0,
  },
};
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn() ? (
                <Navigate to="/home" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/home/*"
            element={
              <PrivatePath>
                <Home />
              </PrivatePath>
            }
          />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/seed-order-bill/:orderId" element={<SeedOrderBill />} />
          <Route
            path="/seed-order-bill-crc/:orderId"
            element={<SeedOrderBillCrc />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TransactionReportCards from "./TransactionReportCards";
import ReceiptsList from "./ReceiptsList";
import SettlementList from "./SettlementList";
import { useParamsQuery } from "../../utils/util";
import { useNavigate } from "react-router-dom";

const TransactionsList = () => {
  const query = useParamsQuery();
  const id = query.get("id");
  console.log(id === "1");
  const [type, setType] = useState("order");
  const [tabValue, setTabValue] = useState(id === "1" ? 1 : 0);
  const navigate = useNavigate();

  useEffect(() => {
    setTabValue(id === "1" ? 1 : 0);
  }, [id]);

  const handleTabChange = (event, newValue) => {
    navigate(`/home/transactions?id=${newValue}`);
  };
  return (
    <Box sx={{ ml: 2, mr: 2, mt: 1 }}>
      <Box display={"flex"} justifyContent={"end"}>
        <FormControl
          size="small"
          variant="outlined"
          margin="normal"
          sx={{ minWidth: 140 }}
        >
          <InputLabel id="type-label">Filter by Type</InputLabel>
          <Select
            labelId="type-label"
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Filter by Type"
          >
            <MenuItem value={"order"}>ORDER</MenuItem>
            <MenuItem value={"cocoon"}>COCOON</MenuItem>
            <MenuItem value={"settlement"}>SETTLEMENT</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TransactionReportCards type={type} />
      <Tabs
        sx={{ mt: 4 }}
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Transactions Tabs"
      >
        <Tab label="Transactions List" />
        <Tab label="Payments to Settle" />
      </Tabs>
      {id !== "1" && <ReceiptsList type={type} />}
      {id === "1" && <SettlementList type={type} />}
    </Box>
  );
};

export default TransactionsList;

import React, { useState } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
  Alert,
} from "@mui/material";
import {
  formatCurrency,
  getMultipartHeader,
  toastError,
  useParamsQuery,
} from "../../utils/util";
import BackBtn from "../widgets/BackBtn";
import { API_ENDPOINT, SILKCART_PC } from "../../constants/constants";
import useCommonGetInit from "../hooks/useCommonGetInit";
import MuPb from "../widgets/MuPb";
import { Link, useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const SettlementPage = () => {
  const query = useParamsQuery();
  const user_id = query.get("id");
  const amount = Number(query.get("amount"));
  const [settlementDate, setSettlementDate] = useState(new Date());
  const [tid, setTid] = useState("");
  const [file, setFile] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [chargePercentage, setChargePercentage] = useState(SILKCART_PC);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-user/${user_id}`
  );

  if (!user_id || user_id.trim() === "") {
    return <Alert severity="error">User ID is not valid</Alert>;
  }
  if (isNaN(amount)) {
    return <Alert severity="error">Amount is not a valid number</Alert>;
  }
  if (isLoading) {
    return <MuPb />;
  }
  if (isError || !data?.user) {
    return <Alert severity="error">Error: {error?.message}</Alert>;
  }

  const subtotalAfterDiscount = amount - discount;
  const charge = (subtotalAfterDiscount * (chargePercentage / 100)).toFixed(2);
  const total = subtotalAfterDiscount - charge;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onSubmit = async () => {
    if (total < 0) {
      toast.error("Settlement amount cannot be negative");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user_id);
    formData.append("amount", total);
    formData.append("settlement_data", settlementDate);
    formData.append("transaction_id", tid);

    try {
      setSubmitting(true);
      const { data } = await axios.post(
        `${API_ENDPOINT}admin/upload-admin-receipt`,
        formData,
        getMultipartHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } catch (error) {
      toastError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <BackBtn />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card sx={{ maxWidth: 700, margin: "auto", mt: 1, p: 3 }}>
          <Typography
            variant="h6"
            fontWeight={600}
            component="h2"
            marginBottom={2}
          >
            Settlement Breakdown
          </Typography>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography>Company Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right" fontWeight={500}>
                <Link to={`/home/orders/user/${data?.user?.silkcart_id}`}>
                  {data?.user?.company}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Orders List:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                <Link to={`/home/transactions/orders/${user_id}`}>
                  Orders List
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Total Amount:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                {formatCurrency(amount)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Discount:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={discount}
                onChange={(e) =>
                  setDiscount(Math.max(0, Number(e.target.value)))
                }
                variant="outlined"
                size="small"
                type="number"
                label="Discount Amount"
                inputProps={{ min: 0, step: 0.01 }}
                InputProps={{
                  startAdornment: "₹",
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>Subtotal:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right" color="primary">
                {formatCurrency(subtotalAfterDiscount)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Service Charge:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                value={chargePercentage}
                onChange={(e) =>
                  setChargePercentage(Math.max(0, Number(e.target.value)))
                }
                variant="outlined"
                size="small"
                type="number"
                label="Charge Percentage"
                inputProps={{ min: 0, step: 0.1 }}
                InputProps={{
                  endAdornment: "%",
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>Charge Amount:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign="right">
                {formatCurrency(charge)} ({chargePercentage}%)
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight={600}>
                Amount to Settle:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                fontWeight={600}
                textAlign="right"
                color="success.main"
              >
                {formatCurrency(total)}
              </Typography>
            </Grid>
          </Grid>

          <TextField
            fullWidth
            size="small"
            value={tid}
            onChange={(e) => setTid(e.target.value)}
            label="Transaction ID"
            variant="outlined"
            sx={{ mb: 2 }}
          />

          <DateTimePicker
            label="Settlement Date"
            size="small"
            value={dayjs(settlementDate)}
            sx={{ width: "100%", mb: 2 }}
            onChange={(newDate) => {
              setSettlementDate(newDate ? newDate.toDate() : new Date());
            }}
          />

          <Button
            fullWidth
            variant="outlined"
            component="label"
            startIcon={<AttachFileIcon />}
            sx={{ mb: 2 }}
          >
            Upload Receipt (PDF)
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </Button>

          {file && (
            <Typography variant="body2" color="text.secondary">
              Selected file: {file.name}
            </Typography>
          )}

          <LoadingButton
            loading={submitting}
            onClick={onSubmit}
            fullWidth
            variant="contained"
            size="large"
            disabled={total < 0}
            sx={{ mt: 2 }}
          >
            Confirm Settlement
          </LoadingButton>
        </Card>
      </LocalizationProvider>
    </Box>
  );
};

export default SettlementPage;

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  IconButton,
  Pagination,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { API_ENDPOINT, PAGE_SIZE, PENDING } from "../../constants/constants";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link } from "react-router-dom";
import useCommonGetInit from "../hooks/useCommonGetInit";
import CustomCircularP from "../widgets/customCircularP";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme?.spacing?.(3) || "24px", // Fallback if theme.spacing is undefined
  marginBottom: theme?.spacing?.(3) || "24px",
  borderRadius: theme?.shape?.borderRadius * 2 || "8px",
  boxShadow: theme?.shadows?.[4] || "0px 6px 12px rgba(0, 0, 0, 0.1)",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: "0.9rem",
  padding: theme?.spacing?.(1, 2) || "8px 16px",
  [theme?.breakpoints?.down("sm") || ""]: {
    fontSize: "0.8rem",
    padding: theme?.spacing?.(0.5, 1) || "4px 8px",
  },
}));

const OrdersList = () => {
  const [page, setPage] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: ordersData,
    isLoading: ordersLoading,
    isError: isOrderError,
    error: orderError,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-orders?page=${page}&type=${type}&order_id=${orderId}&status=${status}&companyName=${companyName}&paymentStatus=${paymentStatus}`
  );
  const {
    data: companyNames,
    isLoading: cLoading,
    isError: isCError,
    error: cError,
  } = useCommonGetInit(`${API_ENDPOINT}admin/get-buyers-names`);

  const ASR_TO_SSP = "asr_to_ssp";
  const CRC_TO_SSP = "crc_to_ssp";
  const SWR_TO_CRC = "swr_to_crc";
  const PAYMENT_STATUSES = ["Pending", "Payment Complete", "Advance Paid"];

  const pageChange = (e, page) => {
    setPage(page);
  };

  if (ordersLoading || cLoading) {
    return <CustomCircularP show={true} />;
  }

  if (isOrderError || isCError) {
    return (
      <StyledPaper elevation={3}>
        <Typography color="error">
          Error loading data: {orderError?.message || cError?.message}
        </Typography>
      </StyledPaper>
    );
  }

  return (
    <StyledPaper elevation={3}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        gutterBottom
        component="div"
        sx={{ fontWeight: "bold" }}
      >
        All Orders
      </Typography>

      {/* Filters */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="type-label">Filter by Type</InputLabel>
            <Select
              labelId="type-label"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Filter by Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={ASR_TO_SSP}>ASR to SSP</MenuItem>
              <MenuItem value={CRC_TO_SSP}>CRC to SSP</MenuItem>
              <MenuItem value={SWR_TO_CRC}>SWR to CRC</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="status-label">Filter by Status</InputLabel>
            <Select
              labelId="status-label"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Indent Received"}>Indent Received</MenuItem>
              <MenuItem value={"Indent Accepted"}>Indent Accepted</MenuItem>
              <MenuItem value={"Indent Processed"}>Indent Processed</MenuItem>
              <MenuItem value={"Indent Rejected"}>Indent Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="company-label">Filter by Company</InputLabel>
            <Select
              labelId="company-label"
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setPage(1);
              }}
              label="Filter by Company"
              disabled={cLoading || isCError}
            >
              <MenuItem value="">
                <em>All Companies</em>
              </MenuItem>
              {companyNames?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="payment-status-label">Filter by Payment</InputLabel>
            <Select
              labelId="payment-status-label"
              value={paymentStatus}
              onChange={(e) => {
                setPaymentStatus(e.target.value);
                setPage(1);
              }}
              label="Filter by Payment"
            >
              <MenuItem value="">
                <em>All Payments</em>
              </MenuItem>
              {PAYMENT_STATUSES.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Search by Order ID"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            sx={{ mt: isMobile ? 0 : 2 }}
          />
        </Grid>
      </Grid>

      {/* Table */}
      <TableContainer sx={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>S. No.</StyledTableCell>
              <StyledTableCell>Order ID</StyledTableCell>
              <StyledTableCell>Qty Required</StyledTableCell>
              <StyledTableCell>Buyer ID</StyledTableCell>
              <StyledTableCell>Supplier ID</StyledTableCell>
              <StyledTableCell>Order Status</StyledTableCell>
              <StyledTableCell>Payment Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData?.data?.map((order, index) => (
              <TableRow key={order.order_id} hover>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {isMobile ? (
                    order.order_id
                  ) : (
                    <>
                      {order.order_id}{" "}
                      <Link to={`order/${order.order_id}`}>
                        <IconButton size="small">
                          <PreviewIcon />
                        </IconButton>
                      </Link>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {order.quantity_required}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {isMobile ? (
                    order.user_id
                  ) : (
                    <>
                      {order.user_id}{" "}
                      <Link to={`user/${order.user_id}`}>
                        <IconButton size="small">
                          <PreviewIcon />
                        </IconButton>
                      </Link>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {isMobile ? (
                    order.supplier_id
                  ) : (
                    <>
                      {order.supplier_id}{" "}
                      <Link to={`user/${order.supplier_id}`}>
                        <IconButton size="small">
                          <PreviewIcon />
                        </IconButton>
                      </Link>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">{order.status}</StyledTableCell>
                <StyledTableCell align="right">
                  {order.payment_status
                    ? order.payment_status.toUpperCase()
                    : PENDING.toUpperCase()}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Pagination
          page={page}
          onChange={pageChange}
          count={Math.ceil(ordersData?.totalItems / PAGE_SIZE) || 1} // Fallback to 1 if undefined
          color="primary"
          size={isMobile ? "small" : "medium"}
          showFirstButton
          showLastButton
        />
      </Box>
    </StyledPaper>
  );
};

export default OrdersList;

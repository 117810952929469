import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CardMedia,
  Pagination,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  Button,
  Chip,
  Skeleton,
  useTheme,
} from "@mui/material";
import useCommonGetInit from "../hooks/useCommonGetInit";
import { API_ENDPOINT, PAGE_SIZE } from "../../constants/constants";
import { NavLink } from "react-router-dom";
import FullImageModal from "../widgets/FullImageModal";
import MuPb from "../widgets/MuPb";
import SearchIcon from "@mui/icons-material/Search";
import { checkFileType } from "../../utils/util";
import { format } from "date-fns";
import ClearIcon from "@mui/icons-material/Clear";

const ReceiptsList = ({ type }) => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [imgOpen, setImgOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const {
    data: receiptsData,
    isLoading: receiptsLoading,
    isError: isReceiptError,
    error: receiptError,
    refetch,
  } = useCommonGetInit(
    `${API_ENDPOINT}admin/get-payment-receipts?page=${page}&status=${status}&type=${type}&orderId=${orderId}`
  );

  useEffect(() => {
    refetch();
  }, [page, status, orderId, refetch]);

  const handleSearch = () => {
    setOrderId(searchInput);
    setPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleImageOpen = (url) => {
    setSelectedImage(url);
    setImgOpen(true);
  };

  const handleImageClose = () => setImgOpen(false);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "Advance Paid":
        return "info";
      case "Payment Complete":
        return "success";
      case "Rejected":
        return "error";
      default:
        return "default";
    }
  };

  if (receiptsLoading) {
    return (
      <Box sx={{ mt: 2 }}>
        <Skeleton variant="rectangular" height={400} />
      </Box>
    );
  }

  if (isReceiptError) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        Error: {receiptError.message}
      </Alert>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Paper sx={{ overflow: "hidden" }}>
        <Box p={2} bgcolor={theme.palette.grey[100]}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="div" fontWeight="bold">
                Payment Receipts
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl size="small" variant="outlined" fullWidth>
                <InputLabel>Filter by Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Filter by Status"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="Advance Paid">Advance Paid</MenuItem>
                  <MenuItem value="Payment Complete">Payment Complete</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyPress={handleKeyPress}
                size="small"
                variant="outlined"
                label="Search by Order ID"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchInput && (
                        <IconButton
                          onClick={() => {
                            setSearchInput("");
                            setOrderId("");
                            setPage(1);
                          }}
                          size="small"
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={handleSearch}
                        color="primary"
                        size="small"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  "Transaction ID",
                  "User ID",
                  "Supplier ID",
                  "Order ID",
                  "Type",
                  "Receipt",
                  "Status",
                  "Payment Amount",
                  "Created On",
                  "Action",
                ].map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      fontWeight: "bold",
                      bgcolor: theme.palette.grey[100],
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {receiptsData?.data?.map((item) => (
                <TableRow
                  key={item._id}
                  hover
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{item.transactionId}</TableCell>
                  <TableCell>{item.user_id}</TableCell>
                  <TableCell>{item.supplier_id}</TableCell>
                  <TableCell>{item.order_id}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    {checkFileType(item.receipt_url) === "image" ? (
                      <CardMedia
                        component="img"
                        image={item.receipt_url}
                        alt="Receipt"
                        sx={{
                          width: 100,
                          height: 100,
                          cursor: "pointer",
                          borderRadius: 1,
                          transition: "transform 0.2s",
                          "&:hover": { transform: "scale(1.05)" },
                        }}
                        onClick={() => handleImageOpen(item.receipt_url)}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        href={item.receipt_url}
                        target="_blank"
                      >
                        View File
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={item.status}
                      color={getStatusColor(item.status)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right">
                    {item.payment_amount
                      ? `₹${Number(item.payment_amount).toFixed(2)}`
                      : `NA`}
                  </TableCell>
                  <TableCell>
                    {format(new Date(item.createdOn), "MMM dd, yyyy HH:mm")}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      component={NavLink}
                      to={`/home/transactions/${item._id}`}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box py={3} display="flex" justifyContent="center">
          <Pagination
            page={page}
            onChange={(_, page) => setPage(page)}
            count={Math.ceil(receiptsData?.totalItems / PAGE_SIZE)}
            color="primary"
            shape="rounded"
          />
        </Box>
      </Paper>

      <FullImageModal
        open={imgOpen}
        handleClose={handleImageClose}
        imgUrl={selectedImage}
      />
    </Box>
  );
};

export default ReceiptsList;

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Search, Refresh } from "@mui/icons-material";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "../../constants/constants";
import { getNewHeader } from "../../utils/util";

const PAGE_SIZE = 10; // Ensure this matches your backend

export default function Customers() {
  const [filters, setFilters] = useState({
    nameSearch: "",
    phone: "",
    device: "",
    type: "",
    pin: "",
  });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    totalRecords: 0,
    totalPages: 1,
    pageSize: PAGE_SIZE,
  });

  useEffect(() => {
    fetchCustomers();
  }, [filters, currentPage]);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        API_ENDPOINT + "admin/rd-get-customers",
        {
          params: {
            page: currentPage,
            ...filters,
          },
          ...getNewHeader(),
        }
      );

      const { data, pagination: paginationData } = response.data;
      setCustomers(data);
      setPagination(paginationData);
    } catch (error) {
      console.error("Error fetching customers:", error);
      toast.error("Failed to fetch customers");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (name) => (e) => {
    setFilters((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  const approveUser = async (user) => {
    try {
      setLoading(true);
      const status = !user.approved;
      await axios.post(
        API_ENDPOINT + "admin/approve-user",
        { silkcart_id: user.silkcart_id, status },
        getNewHeader()
      );

      setCustomers((prev) =>
        prev.map((c) =>
          c.silkcart_id === user.silkcart_id ? { ...c, approved: status } : c
        )
      );
      toast.success("User approval status updated");
    } catch (error) {
      console.error("Approval error:", error);
      toast.error("Failed to update approval status");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search Name"
              value={filters.nameSearch}
              onChange={handleFilterChange("nameSearch")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Phone Number"
              value={filters.phone}
              onChange={handleFilterChange("phone")}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Select
              fullWidth
              value={filters.type}
              onChange={handleFilterChange("type")}
              displayEmpty
              label="User Type"
            >
              <MenuItem value="">All Types</MenuItem>
              <MenuItem value="asr">ASR</MenuItem>
              <MenuItem value="ssp">SSP</MenuItem>
              <MenuItem value="crc">CRC</MenuItem>
              <MenuItem value="swr">SWR</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Device ID"
              value={filters.device}
              onChange={handleFilterChange("device")}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="PIN Code"
              value={filters.pin}
              onChange={handleFilterChange("pin")}
            />
          </Grid>

          <Grid item xs={12} md={1}>
            <Button
              fullWidth
              variant="contained"
              onClick={fetchCustomers}
              startIcon={<Refresh />}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Card>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="customer table">
            <TableHead sx={{ bgcolor: "background.default" }}>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Bank Details</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {customers.map((customer, index) => (
                <TableRow key={customer.silkcart_id}>
                  <TableCell>
                    {(currentPage - 1) * pagination?.pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight="bold">
                      <Link to={`${customer.silkcart_id}`}>
                        {customer.name}
                      </Link>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ID: {customer.silkcart_id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      LN: {customer.licence_number}
                    </Typography>
                  </TableCell>

                  {/* Rest of the table cells remain same */}
                  <TableCell>
                    <Typography>{customer.phone}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {customer.user_type.toUpperCase()} (
                      <strong>
                        {customer.vc}
                        {" | "}
                        {customer.vn})
                      </strong>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body2">
                      {customer.bank.name} ({customer.bank.account_type})
                    </Typography>
                    <Typography variant="body2">
                      A/C: {customer.bank.account_no}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body2">
                      {customer.area}, {customer.taluk}
                    </Typography>
                    <Typography variant="body2">
                      {customer.district}, {customer.state}
                    </Typography>
                    <Typography variant="body2">PIN: {customer.pin}</Typography>
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        bgcolor: customer.approved
                          ? "success.light"
                          : "error.light",
                        px: 1,
                        borderRadius: 1,
                      }}
                    >
                      {customer.approved ? "Approved" : "Pending"}
                    </Box>
                    <Typography variant="body2" mt={0.5}>
                      {new Date(customer.createdOn).toLocaleDateString()}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      color={customer.approved ? "error" : "success"}
                      onClick={() => approveUser(customer)}
                    >
                      {customer.approved ? "Disapprove" : "Approve"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            Showing {customers.length} of {pagination.totalRecords} results
          </Typography>

          <Pagination
            count={pagination.totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Card>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
